import { SvgProps } from "../types";
import { SvgPath } from "./base";

export const AddEndNodeAfterIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M18.576 6.707c-2.645 0-4.74 1.94-5.125 4.48.022.005.044.013 0 .012H9.13c-.027.008-.016 0-.004-.006V7.525H.175v8.95h8.95l-.004-3.897h4.254c.002-.002 0-.002.01-.008l.002.008c.036-.009.017 0 0 .004.27 2.666 2.454 4.711 5.19 4.711A5.303 5.303 0 0 0 23.868 12a5.303 5.303 0 0 0-5.293-5.293zm-5.19 5.875v-.004h-.011c-.004.005.001.007.012.004zm.065-1.395c-.02-.003-.04-.005-.002.01l.002-.01zm5.125-3.101A3.904 3.904 0 0 1 22.49 12a3.904 3.904 0 0 1-3.914 3.914A3.904 3.904 0 0 1 14.662 12a3.904 3.904 0 0 1 3.914-3.914zm-17.02.818h6.19v6.192h-6.19Zm17.024.489A2.62 2.62 0 0 0 15.97 12a2.62 2.62 0 0 0 2.61 2.607A2.617 2.617 0 0 0 21.188 12a2.617 2.617 0 0 0-2.608-2.607zm0 1.378c.687 0 1.229.542 1.229 1.229s-.542 1.229-1.229 1.229A1.22 1.22 0 0 1 17.35 12a1.22 1.22 0 0 1 1.23-1.229z"
    />
);

export const AddIncomingLinkIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M6.23374.70117c-.38146-.0009-.69123.30799-.6914.68946v2.57226H2.9857c-.3807.00017-.68929.30875-.68946.68945.00017.3807.30875.68929.68946.68946h2.55664v2.49218c.00017.38147.30994.69037.6914.68946.3807-.00017.68929-.30875.68946-.68946V5.3418h2.50586c.3807-.00017.68928-.30875.68945-.68946-.00017-.3807-.30875-.68928-.68945-.68945H6.9232V1.39063c-.00017-.3807-.30875-.68929-.68946-.68946Zm4.61915 4.9961v5.61328h-4.7754c-.34313-1.24302-1.30491-2.25-2.65234-2.25-1.61577 0-2.8835 1.32368-2.8835 2.93945s1.26773 2.93945 2.8835 2.93945c1.34743 0 2.3092-1.00698 2.65234-2.25h4.7754v5.61328h12.60546V5.69727Zm1.38085 1.38086h9.84375v9.84375h-9.84375zm-8.80859 3.36132c.87022 0 1.56055.69033 1.56055 1.56055 0 .87022-.69033 1.56055-1.56055 1.56055-.87022 0-1.56055-.69033-1.56055-1.56055 0-.87022.69033-1.56055 1.56055-1.56055z"
    />
);

export const AddLinkIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M11.9834 4.52344a.87874.87874 0 0 0-.8789.8789v2.07618H9.06542a.87874.87874 0 0 0-.8789.8789.87874.87874 0 0 0 .8789.8789h2.03906v2.00196a.87874.87874 0 0 0 .8789.87695.87874.87874 0 0 0 .87892-.87695V9.23633h2.03906a.87874.87874 0 0 0 .8789-.8789.87874.87874 0 0 0-.8789-.87891H12.8623V5.40234a.87874.87874 0 0 0-.8789-.8789Zm-8.5918 6.97265c-1.7179 0-3.1289 1.41101-3.1289 3.12891 0 1.7179 1.411 3.1289 3.1289 3.1289 1.39292 0 2.3432-.97534 2.74414-2.24218.03356.0165-.03148-.01991.00391-.0078h11.70898c.0457-.01457-.0427.0277 0 .0059.39718 1.27302 1.36243 2.24414 2.75977 2.24414 1.7179 0 3.1289-1.411 3.1289-3.1289 0-1.7179-1.411-3.12891-3.1289-3.12891-1.38758 0-2.34457.98865-2.75 2.24804-.04663-.01369.05416.0078.0059.002H6.1436c-.40094-1.26684-1.35904-2.25-2.75195-2.25zm0 1.75782c.7681 0 1.3711.603 1.3711 1.37109 0 .76809-.603 1.3711-1.3711 1.3711-.76809 0-1.3711-.60301-1.3711-1.3711 0-.76809.60301-1.3711 1.3711-1.3711zm17.2168 0c.76809 0 1.3711.603 1.3711 1.37109 0 .76809-.60301 1.3711-1.3711 1.3711-.76809 0-1.3711-.60301-1.3711-1.3711 0-.76809.60301-1.3711 1.3711-1.3711z"
    />
);

export const AddOutgoingLinkIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M17.6914.70117c-.3807.00017-.68928.30875-.68945.68946v2.57226H14.4961c-.3807.00017-.68928.30875-.68945.68945.00017.3807.30875.68929.68945.68946h2.50586v2.49218c.00017.3807.30875.68929.68946.68946.38146.0009.69123-.30799.6914-.68946V5.3418h2.55664c.3807-.00017.68929-.30875.68946-.68946-.00017-.3807-.30875-.68928-.68946-.68945h-2.55664V1.39063c-.00017-.38147-.30994-.69037-.6914-.68946ZM.4668 5.69727v12.60546h12.60547v-5.61328h4.77539c.34314 1.24302 1.30491 2.25 2.65234 2.25 1.61577 0 2.9414-1.32368 2.9414-2.93945S22.11578 9.06055 20.5 9.06055c-1.34743 0-2.3092 1.00698-2.65234 2.25h-4.7754V5.69727H.4668zm1.38086 1.38086h9.84375v9.84375H1.84766ZM20.5 10.43944c.87022 0 1.56055.69033 1.56055 1.56055 0 .87022-.69033 1.56055-1.56055 1.56055-.87022 0-1.56055-.69033-1.56055-1.56055 0-.87022.69033-1.56055 1.56055-1.56055z"
    />
);

export const AddRoutineListAfterIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M.17578 7.5254v8.9492H9.125v-3.78515h5.5879v3.78516h8.9492V7.52539h-8.9492v3.78516H9.125V7.52539Zm1.3789 1.3789H7.7461v6.1914H1.5547Zm14.53712 0h6.1914v6.1914h-6.1914zm3.09765.60547c-.38147.00016-.69036.30994-.68945.6914v1.19922h-1.1914c-.38071.00017-.68929.30875-.68946.68945.00017.3807.30875.68929.68945.68946H18.5v1.15234c.00017.3807.30875.68928.68945.68945.3807-.00017.68929-.30875.68946-.68945V12.7793h1.1621c.38071-.00017.68929-.30875.68946-.68946-.00017-.3807-.30875-.68928-.68945-.68945H19.8789v-1.19922c.0009-.38146-.30799-.69124-.68946-.6914Z"
    />
);

export const AddRoutineListBeforeIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M23.6621 7.5254v8.9492h-8.9492v-3.78515H9.125v3.78516H.1758V7.52539H9.125v3.78516h5.5879V7.52539Zm-1.3789 1.3789h-6.1914v6.1914h6.1914zm-14.5371 0H1.55468v6.1914h6.1914zm-3.09766.60547c.38147.00016.69036.30994.68945.6914v1.19922h1.1914c.38071.00017.68929.30875.68946.68945-.00017.3807-.30875.68929-.68945.68946H5.33789v1.15234c-.00017.3807-.30875.68928-.68945.68945-.3807-.00017-.68929-.30875-.68946-.68945V12.7793h-1.1621c-.38071-.00017-.68929-.30875-.68946-.68946.00017-.3807.30875-.68928.68945-.68945h1.16211v-1.19922c-.0009-.38146.30799-.69124.68946-.6914z"
    />
);

export const BranchIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M18.7 2.5a3.1 3.1 0 0 0-3 2.1c-1 .2-2 .8-2.7 1.5l-2 2.5c-.5 1-2 2.1-3.6 2.4a3.1 3.1 0 0 0-2.9-2.1A3.1 3.1 0 0 0 1.4 12a3.1 3.1 0 0 0 3.1 3.1 3.1 3.1 0 0 0 3-2.1c1.6.2 3 1.4 3.6 2.3.5.7 1 1.7 2 2.5.6.7 1.5 1.3 2.6 1.5a3.1 3.1 0 0 0 3 2.2 3.1 3.1 0 0 0 3.1-3 3.1 3.1 0 0 0-3-3.2 3.1 3.1 0 0 0-3 2c-.5 0-1-.4-1.5-.9-.6-.6-1.2-1.4-1.6-2.1a7.3 7.3 0 0 0-2.5-2.3c1.1-.7 2-1.5 2.5-2.4.4-.6 1-1.5 1.6-2.1.5-.5 1-.8 1.5-1a3.1 3.1 0 0 0 3 2.2 3.1 3.1 0 0 0 3-3.1 3.1 3.1 0 0 0-3-3.1Zm0 1.7a1.4 1.4 0 0 1 1.4 1.4 1.4 1.4 0 0 1-1.4 1.3 1.4 1.4 0 0 1-1.3-1.3 1.4 1.4 0 0 1 1.3-1.4ZM4.5 10.6A1.4 1.4 0 0 1 5.9 12a1.4 1.4 0 0 1-1.4 1.4A1.4 1.4 0 0 1 3.1 12a1.4 1.4 0 0 1 1.4-1.4Zm14.2 6.5a1.4 1.4 0 0 1 1.4 1.3 1.4 1.4 0 0 1-1.4 1.4 1.4 1.4 0 0 1-1.3-1.4 1.4 1.4 0 0 1 1.3-1.3Z"
    />
);

export const DeleteNodeIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M5.697 5.697v12.606h12.606V5.697Zm1.381 1.381h9.844v9.844H7.078ZM9.754 9a.69.69 0 0 0-.504 1.17l1.793 1.846-1.834 1.783a.69.69 0 0 0 .96.988l1.835-1.783 1.736 1.787a.69.69 0 1 0 .99-.96l-1.738-1.788 1.799-1.748a.69.69 0 0 0-.96-.988l-1.8 1.748-1.793-1.846A.69.69 0 0 0 9.754 9Z"
    />
);

export const MoveNodeIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M11.56 2.5 9.37 4.13c-.81.66.2 1.59.83 1.1l1.04-.77V7H6.96v4.29H4.42l.78-1.05c.52-.79-.53-1.5-1.1-.82L2.45 11.6c-.18.31-.17.57 0 .82l1.63 2.19c.54.62 1.63-.22 1.1-.83l-.83-1.11h2.6v4.35h4.28v2.55l-1.04-.78c-.75-.53-1.54.53-.82 1.1l2.18 1.64c.3.18.6.17.82 0l2.19-1.63c.73-.64-.23-1.56-.83-1.1l-1.12.83v-2.6h4.36v-4.36h2.65l-.83 1.11c-.54.68.67 1.43 1.1.83l1.63-2.19c.21-.27.16-.59 0-.82L19.9 9.42c-.55-.7-1.65.07-1.1.82l.78 1.05h-2.6V7h-4.36V4.4l1.12.84c.75.55 1.56-.6.83-1.1L12.38 2.5a.6.6 0 0 0-.82 0zM8.34 8.38h7.26v7.26H8.34Z"
    />
);

export const RedirectIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M3.7 12.3a1 1 0 0 1 1.4 0l.9.9V9a6 6 0 0 1 12 0v11c0 .6-.5 1-1 1s-1-.5-1-1V9a4 4 0 1 0-8 0v4.2l.9-1a1 1 0 1 1 1.4 1.5l-2.6 2.6a1 1 0 0 1-1.4 0l-2.6-2.6a1 1 0 0 1 0-1.4z"
    />
);

export const UnlinkNodeIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="m14.93 2.85 1.81 1.82-1.77 1.77c-.64.63.4 1.52.97.98l1.78-1.77 1.76 1.76c.62.62 1.52-.46.98-.97L18.7 4.67l1.81-1.8c.64-.74-.34-1.54-.97-.98l-1.82 1.8-1.81-1.82c-.62-.55-1.5.44-.98.98ZM.47 5.7v12.6h12.6v-5.6h4.78c.34 1.24 1.3 2.25 2.65 2.25A2.95 2.95 0 0 0 23.44 12a2.95 2.95 0 0 0-2.94-2.94c-1.35 0-2.3 1-2.65 2.25h-4.78V5.7Zm1.38 1.38h9.84v9.84H1.85Zm18.65 3.36c.87 0 1.56.69 1.56 1.56 0 .87-.69 1.56-1.56 1.56-.87 0-1.56-.69-1.56-1.56 0-.87.69-1.56 1.56-1.56z"
    />
);

export const UnlinkedNodesIcon = (props: SvgProps) => (
    <SvgPath
        props={props}
        d="M12.12 3.61c-.86-.01-1.7.4-2.26 1.04-.13.08-.14.18-.03.28.17.28.32.63.68.69.36.1.58-.26.86-.4.45-.26 1.1-.28 1.45.12.29.37.1.9-.22 1.2-.45.43-1.12.7-1.31 1.35-.09.33-.02.67-.02 1.02l.02.74h1.3l.1-1.35c.34-.37.83-.6 1.16-1 .72-.77.82-2.1.1-2.9a2.42 2.42 0 0 0-1.83-.79Zm-.18 6.73a1 1 0 0 0-.97 1.23c.12.62.9.95 1.43.64.55-.26.7-1.06.3-1.5a1.01 1.01 0 0 0-.76-.37zM3.4 11.5a3.14 3.14 0 0 0-3.13 3.12 3.14 3.14 0 0 0 3.13 3.13c1.4 0 2.71-.91 2.93-2.2l11.5-.07c.13 1.06 1.39 2.27 2.78 2.27a3.14 3.14 0 0 0 3.13-3.12 3.14 3.14 0 0 0-3.12-3.13 2.8 2.8 0 0 0-2.8 2.25l-11.57.05c-.1-1.32-1.46-2.3-2.85-2.3Zm0 1.75c.77 0 1.37.6 1.37 1.37 0 .77-.6 1.38-1.37 1.38-.77 0-1.37-.6-1.37-1.38 0-.76.6-1.37 1.37-1.37zm17.21 0c.77 0 1.38.6 1.38 1.37 0 .77-.6 1.38-1.38 1.38-.76 0-1.37-.6-1.37-1.38 0-.76.6-1.37 1.38-1.37z"
    />
);
